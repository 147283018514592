@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap");

@tailwind base;

@tailwind components;

@tailwind utilities;

::selection {
  @apply bg-yellow-300;
  @apply text-gray-900;
}

.fancyquote {
  quotes: "\201C""\201D""\2018""\2019";
}

.fancyquote::before {
  @apply text-yellow-300;
  @apply mr-2;
  font-size: 3em;
  content: open-quote;
  line-height: 0.1em;
  vertical-align: -0.4em;
}
